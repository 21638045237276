<template>
  <div class="schoolBody">
    <div class="indexTeacher">
      <div class="navList">
        <h4>账户设置</h4>
        <div class="uploadList">
          <button type="button" class="uploadBtn cursorStyle" @click="passWordBox">
            <i class="iconList modify"></i>
            修改密码
          </button>
          <button type="button" class="uploadBtn quit cursorStyle" @click="logout">
            退出登录
          </button>
        </div>
      </div>
      <div class="information">
        <h5 class="inforTitle">
          <span></span>
          <em>个人信息</em>
          <i class="schoolmaster">{{superviseRank[formSuper.superviseRank]}}</i>
          <i class="editor"  v-show="!edit" @click="handleEdit"><b></b>编辑</i>
        </h5>
        <el-form :model="formSuper" :rules="rules" ref="formSuper">
          <el-form-item class="inforText" label="姓名:" prop="superviseName">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formSuper.superviseName"
                clearable>
            </el-input>

            <p v-else>{{formSuper.superviseName}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="手机:" prop="superviseMobile">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formSuper.superviseMobile"
                clearable>
            </el-input>
            <p v-else>{{formSuper.superviseMobile}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="身份证:" prop="cardId">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formSuper.cardId"
                clearable>
            </el-input>
            <p v-else>{{formSuper.cardId ? formSuper.cardId : '暂无'}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="性别:"  style="display: inline">
            <el-select v-model="formSuper.sex" placeholder="请选择性别" v-if="edit" >
              <el-option label="男" :value=1></el-option>
              <el-option label="女" :value=2></el-option>
            </el-select>
            <p v-else>{{formSuper.sex ==1?'男':'女'}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="年龄:">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入年龄"
                v-model="formSuper.superviseAge"
                clearable>
            </el-input>
            <p v-else>{{formSuper.superviseAge}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="籍贯:">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formSuper.nativePlace"
                clearable>
            </el-input>
            <p v-else>{{formSuper.nativePlace?formSuper.nativePlace:"暂无"}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="学历:">
            <el-select v-model="formSuper.education" placeholder="请选择学历" v-if="edit" >
              <el-option label="无学历" :value=0></el-option>
              <el-option label="小学" :value=1></el-option>
              <el-option label="初中" :value=2></el-option>
              <el-option label="高中（中专）" :value=3></el-option>
              <el-option label="大专" :value=4></el-option>
              <el-option label="本科" :value=5></el-option>
              <el-option label="研究生" :value=6></el-option>
            </el-select>
            <p v-else>{{ education[formSuper.education]}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="权限类型:" style="display: inline">
            <el-select disabled v-model="formSuper.superviseRank" placeholder="请选择权限" v-if="edit" >
              <el-option label="全国" :value=1></el-option>
              <el-option label="省级" :value=2></el-option>
              <el-option label="市级" :value=3></el-option>
              <el-option label="区县" :value=4></el-option>
            </el-select>
            <p v-else>{{superviseRank[formSuper.superviseRank]}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="管辖区域:" style="display: inline">
<!--            <el-cascader-->
<!--                v-if="edit"-->
<!--                ref="cascaderUnit"-->
<!--                v-model="value"-->
<!--                :options="options"-->
<!--                :props="{ checkStrictly: true }"-->
<!--                @change="handleChange"-->
<!--            ></el-cascader>-->
            <p v-if="formSuper.jurisdictionProvince||formSuper.jurisdictionCity">
              {{formSuper. jurisdictionProvinceName}}
              {{formSuper.jurisdictionProvinceName!=='' && formSuper.jurisdictionCityName!=='' ? '/' :''}}
              {{formSuper.jurisdictionCityName}}</p>
            <div v-else>
              <p v-if="formSuper.jurisdictionProvince||formSuper.jurisdictionCity">
                {{formSuper.jurisdictionProvinceName}}
                {{formSuper.jurisdictionProvinceName!=='' && formSuper.jurisdictionCityName!=='' ? '/' :''}}
                {{formSuper.jurisdictionCityName}}</p>
              <p v-else>暂无</p>
            </div>
          </el-form-item>
          <el-form-item class="inforText" label="职务职位:"  style="display: inline">
            <el-select disabled v-model="formSuper.position" placeholder="请选择职位" v-if="edit" >
              <el-option label="主管" :value=1></el-option>
              <el-option label="专员" :value=2></el-option>
            </el-select>
            <p v-else>{{position[formSuper.position]}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="所在地区:"  style="display: inline">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formSuper.area"
                clearable>
            </el-input>
            <p v-else>{{formSuper.area}}</p>
          </el-form-item>
          <div class="clearfix"></div>
          <el-form-item class="inforText" label="职位证明:">
              <div v-if="edit" style="display:block ;margin-left:.3rem">
                <el-upload
                    list-type="picture-card"
                    :auto-upload="true"
                    :headers="{ token: $store.state.userInfo.token }"
                    :action="constant.UPLOAD_URL + '/file/upload'"
                    :file-list="picList"
                    :on-success="onSchoolLogoUploaded"
                    :on-exceed="schoolLogoExceed"
                    :on-remove="removeSchoolbusinessList"
                    accept="png,jpg"
                    :before-upload="beforeAvatarUpload"
                    :limit="1"
                    class="topPlus">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
<!--                  <img v-else :src="constant.URL + formSuper.proveImage" class="avatar" alt="">-->
                </el-upload>
                </div>
              <div v-else class="licensePic" >
                <span class="defaultPic" v-if="!formSuper.proveImage"></span>
                <img v-else :src="constant.URL + formSuper.proveImage" alt="" >
              </div>
          </el-form-item>
          <div class="clearfix"></div>
          <div v-if="edit" style="margin-top: .3rem;margin-left:.4rem">
            <el-button size="small" type="success" class="uploadBtn active" @click="onsubmit">保存
            </el-button>
            <el-button slot="trigger"
                       style="margin-left: 10px;"
                       size="small"
                       type="primary"
                       class="uploadBtn"  @click="closeEdit">取消
            </el-button>
          </div>
      </el-form>
      </div>
      <amend-password ref="amend" v-if="visibleShow" @visibleColse="visibleColse" :status="2"></amend-password>
    </div>
  </div>
</template>

<script>
 import AmendPassword from "@/views/user/schoolIndex/amendPassword";

 export default{
   components: {AmendPassword},
   data(){
      //验证
     var validateCardId = (rule,value,callback) => {
       // return this.common.validateIdCard(value)
       //     ? callback()
       //     : callback(new Error("请输入正确格式身份证号"))
       if (value === '') {
         return callback()
       }else{
         return this.common.validateIdCard(value) ? callback() : callback(new Error("请输入正确格式的身份证号"))
       }
     }
     var validateName = (rule,value,callback)=>{
       return this.common.textNumberCheck(value,25)
           ? callback()
           : callback(new Error("请输入正确格式姓名"))
     }
     var validatePhone = (rule, value, callback) => {
       return this.common.isMobile(value)
           ? callback()
           : callback(new Error("请输入正确格式联系电话"))
     }
     return  {
       edit : false,
       visibleShow: false,
       schoolLogo : '',
       formSuper :{},
       atteTeacher :{},
       listClass:[],
       value: "",
       atteGradeList: [],
       atteGradeMap: {},

       teacherAvatar:[],
       education:{
         '0':'无学历',
         '1':'小学',
         '2':'初中',
         '3':'高中(中专)',
         '4':'大专',
         '5':'本科',
         '6':'研究生',
       },
       position:{
         '1':'主管',
         '2':'专员',
       },
       superviseRank:{
         '1':'全国',
         '2':'省级',
         '3':'市级',
         '4':'区县',
       },
       options:[],
       picList:[{
         url:''
       }],
       proveImage:'',
       birthTime:'',
       photo:'',
       rules: {
         superviseName: [
           { required: true,validator: validateName , message: '请输入姓名', trigger: 'blur' },
         ],
         superviseMobile: [
           { required: true,validator: validatePhone ,message: '请输入手机号', trigger: 'blur' },
         ],
         cardId: [
           { required: false,validator: validateCardId,message: '请输入身份证号', trigger: 'blur' },
         ]
       },
       pathLabels:'',
       cityid:''

     }
   },
   methods:{
     handleChange(value) {
       const checkedNodes = this.$refs["cascaderUnit"].getCheckedNodes();
       this.pathLabels = checkedNodes[0].pathLabels //名字
       this.cityid = value //Id
     },
     // 获取三级城市联动checkedNodes
     cascaded() {
       this.api.datav.cascaded().then((res) => {
         this.options = res.data;
       });
     },
     drawTimingFn() {
       this.setData();
       this.drawTiming = setInterval(() => {
         this.setData();
       }, 6000);
     },
     setData() {
       let data = {
         province: this.$store.state.schoolInfo.atteSchool.province,
         city: this.$store.state.schoolInfo.atteSchool.trueCity,
       };
       this.$store.dispatch("getFullData", data);
     },
     //查看督导
     getSupInfo(){
       let that = this
       let id = that.$store.state.userInfo.superviseId
       this.api.admin.superviseInfo( id).then(res =>{
         that.formSuper = res.data
         that.proveImage = res.data.proveImage
         if(res.data.proveImage){
           that.picList = [{"url":this.constant.URL + res.data.proveImage}];
         }
       //把名称跟手机号存在 缓存里;
         var superviseName = {
           superviseName:res.data.superviseName,
           phone:res.data.superviseMobile
         }
         localStorage.setItem('supervisePassWord',JSON.stringify(superviseName))
       })
     },

     //编辑督导
     onsubmit(){
       let self = this
       if(!this.picList.length){
         this.$notice({
           message: '图片不能为空'
         }).isShow()
         return false
       }
       self.$refs.formSuper.validate((valid) => {
         if (!valid) {
           return
         }
         self.formSuper.jurisdictionProvince= this.formSuper.jurisdictionProvince;
         self.formSuper.jurisdictionCity= this.formSuper.jurisdictionCity;
         self.formSuper.jurisdictionProvinceName = this.formSuper.jurisdictionProvinceName
         self.formSuper.jurisdictionCityName = this.formSuper.jurisdictionCityName
          //console.log("地址",self.cityid[1],self.pathLabels[1],self.cityid[0],self.pathLabels[0])
         self.formSuper.proveImage = this.proveImage;
         let data = {
           ...self.formSuper
         }
         let id = self.$store.state.userInfo.superviseId
         this.api.admin.superviseSubmit(data,id).then((res) => {
             if (res.flag) {
               this.$notice({
                 type:'success',
                 message: res.message
               }).isShow();
               self.getSupInfo()
             } else {
               return this.$notice({
                 message: res.message
               }).isShow();
             }
             self.edit = false
           })

       })
       this.getSupInfo();
     },
     passWordBox() {
       this.visibleShow = true;
     },
     visibleColse() {
       this.visibleShow = false;
     },
     //退出
     logout() {
       this.$store.dispatch('out', this)
     },
     //头像上传
     onSchoolLogoUploaded(res) {
       this.proveImage = res.data.filename
       this.picList = [{
         url: this.constant.URL +  this.proveImage
       }]
     },
     schoolLogoExceed(){
       this.$notice({
         message:'只能上传一张图片'
       }).isShow()
     },
     beforeAvatarUpload(file) {
       const isJPG = file.type === "image/jpeg" || file.type === "image/png";

       if (!isJPG) {
         this.$notice({
           message: "上传头像图片只能是 JPG/PNG 格式!",
         }).isShow();
       }

       return isJPG;
     },


     removeSchoolbusinessList(res){
       let len = null;
       this.picList.forEach((item,index)=>{
         if(item.imgUrl === res.imgUrl){
           len = index;
         }
       });
       this.$delete(this.picList,len);
     },


     //编辑督导
     handleEdit() {
       this.edit = true;
     },

     closeEdit() {
       this.edit = false;
       this.getSupInfo()
     },
   },

   mounted() {
     this.getSupInfo();
     this.cascaded();
   },
   watch: {
     value() {
       if (this.$refs.cascaderUnit) {
         this.$refs.cascaderUnit.dropDownVisible = false; //监听值发生变化就关闭它
       }
     },
   },

 }
</script>

<style lang="scss" scoped>
.schoolBody{
  width: 100%;
  height: 100vh;
  background: #f1f1f1;
}
/deep/.el-upload-list--picture-card .el-upload-list__item{
  width: 68px;
  height: 68px;
 }
.defaultPic{
  display: block;
  width: 80px;
  height:80px;
  background: url(../../assets/img/superPic/img.png) no-repeat;
  background-size: 100% 100%;
}

.indexTeacher{
   width: 1434px;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
  height: 100vh;
  background:#fff;
  h6{
    color: #333;
    font-size: 16px;
  }
  span{
    font-size: 20px;
    font-weight: bold;
    color: #FFA000;
  }
}
.thacherLogo{
  float: left;
  width: 64px;
  height: 64px;
  border: 1px dashed #333;
  border-radius: 4px;
}
/deep/.avatar-uploader-icon{
  line-height:64px;
}
.teacherName{
   margin-left: 20px;
   float: left;
}

.cursorStyle{
  cursor:pointer;
}
/deep/.degree   .el-input .el-input__inner{
  width: 89px;
}
/deep/ .el-input--mini .el-input__icon{
  line-height: inherit;
}
/deep/.el-form{
  overflow: hidden;

}
.information{
  margin-bottom: 50px;
}


/deep/ .el-textarea {
  width: 300px;
  position: relative;
}

/deep/ .el-input__count {
  position: absolute;
  right: 10px;
}

/deep/ .teachingWidth {
  .el-input__inner {
    width: 300px;
    height: 40px;
    padding-right : 70px;
  }

  .el-textarea__inner {
    width: 300px;
    resize: none;
    margin-top: 10px;
    height: 83px;
  }
}


/deep/ .el-upload--picture-card i {
  line-height: 64px;
}

/deep/ .topPlus {


  .el-upload {

  }
}

.teachingPic {
  height: 80px;

  .el-upload {
    height: 80px;
  }
}


/deep/ .editable {
  width: 98px;

  .el-input {
    float: left;
    width: 98px;
    height: 40px;

    .el-input__inner {
      float: left;
      width: 98px;
      height: 40px;
    }
  }

  .el-textarea__inner {
    width: 300px;
    resize: none;
    margin-top: 10px;
    height: 83px;
  }

}

/deep/ .selectWidth {
  width: 80px;

  .el-select {
    float: left;
    width: 80px;
  }

  .el-input {
    float: left;
    width: 80px;

    .el-input__inner {
      width: 80px;
      padding-right: 20px;
    }
  }
}

//上传学校logo
///deep/.topPlus{
//  margin-top: 12px;
//}
.fl {
  float: left;
}

.fr {
  float: right;
}

.none {
  display: none;
}

.schoolIndex {
  width: 1200px;
  background: #fff;
  padding: 0 40px;
  margin: 0 auto;
}

.navList {
  overflow: hidden;

  h4 {
    float: left;
    font-size: 24px;
    font-weight: bold;
    color: #BA3037;
    margin-top: 30px;
  }

  .uploadList {
    float: right;
    margin-top: 27px;

    .uploadBtn {
      background: #FFFFFF;
      border: 1px solid #2E3C6B;
      border-radius: 15px;
      padding: 6px 15px 6px 20px;
      margin-left: 7px;
      color: #36467B;
      font-size: 18px;
      //background:url(../../assets/img/schoolIndex/Btn_Bg_H_Blue.png) no-repeat;
      &:hover {
        //background:url(../../assets/img/schoolIndex/Btn_Bg_H_Blue.png) no-repeat top center;
        //background-size: 100% 100%;
        //width: 148px;
        //height: 63px;
        border: 1px solid transparent;
        color: #fff;
        background: #36467B;
        //padding: 0;
        .iconList {
          display: inline-block;
          width: 16px;
          height: 16px;
        }

        .set {
          background: url(../../assets/img/schoolIndex/Icon_Btn_SetBg_H.png) no-repeat;
          background-size: 100% 100%;
        }

        .transfer {
          background: url(../../assets/img/schoolIndex/Icon_Btn_ZrYz_H.png) no-repeat;
          background-size: 100% 100%;
        }

        .modify {
          background: url(../../assets/img/schoolIndex/Icon_Btn_XgMm_H.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .quit {
      border: 1px solid #BA3037;
      color: #BA3037;
      background: #FFFFFF;
      border-radius: 15px;
      padding: 6px 15px 6px 20px;
      margin-left: 7px;
      font-size: 18px;

      &:hover {
        background: linear-gradient(232deg, #B93037, #EF525B);
        box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
      }
    }

    .iconList {
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    .set {
      background: url(../../assets/img/schoolIndex/Icon_Btn_SetBg.png) no-repeat;
      background-size: 100% 100%;
    }

    .transfer {
      background: url(../../assets/img/schoolIndex/Icon_Btn_ZrYz.png) no-repeat;
      background-size: 100% 100%;
    }

    .modify {
      background: url(../../assets/img/schoolIndex/Icon_Btn_ChaMm.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}

.inforTitle {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin: 30px 0;
  padding-left: 12px;
  position: relative;

  span {
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #BA3037;
    border-radius: 2px;
    position: absolute;
    top: 7px;
    left: 0;
  }

  em {
    vertical-align: middle;
  }

  i {
    vertical-align: middle;
    margin-left: 15px;
  }

  .schoolmaster {
    padding: 3px 11px;
    height: 24px;
    border: 1px solid #BA3037;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #BA3037;
    font-style: normal;
  }

  .editor {
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #36467B;
    position: absolute;
    top: 0;
    left: 3rem;
    cursor: pointer;

    b {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(../../assets/img/schoolIndex/Icon_Set_Edit.png) no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;

    }
  }
}

.inforName {
  overflow: hidden;
}
.thacherHeder{
  overflow: hidden;
  margin-bottom: 20px;
}
/deep/  .el-form-item__label {
  display: inline-block;
  color: #888;
  font-size: 16px;
  margin-right: 10px;
  line-height: 40px;
  width: 155px;
  text-align: right
}
/deep/.el-form-item__content{
  display: inline-block;
}

.inforText {
  float: left;
  font-size: 16px;
  font-weight: 400;
  margin-right: 30px;
  margin-bottom: 20px;


  .color {
    color: #333;
  }

  p {
    display: inline-block;
    color: #333333;
    line-height: 40px;
    width: 368px;
  }
}

.leftPic {
  img {
    display: inline-block;
    margin-right: 30px;
    width: 100px;
    height: 80px;
  }
}
.uploadBtn {
  padding: 10px 32px;
  border: 1px solid #BA3037;
  //background: linear-gradient(232deg, #B93037, #EF525B);
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #BA3037;
  background: #fff;
  margin-top: 20px;
}

.active {
  background: url(../../assets/img/schoolIndex/Btn_Bg_Save_U.png) no-repeat top center;
  background-size: 100% 100%;
  width: 117px;
  height: 60px;
  border: none;
  color: #fff;
}

/deep/ .el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: #BA3037;
  margin-top: 13px;
  margin-bottom: 20px;

  img {
    display: inline-block;
    width: 100px;
    height: 80px;
  }
}

.teachers {
  width: 420px;
  margin-bottom: 20px;
  margin-right: 70px;
  height: 138px;
}
.teachers ::after{
  display: block;
  clear: both
}

.radius {
  padding: 12px;
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  border-radius: 2px;
  margin-right: 50px;
  position: relative;
}

.teachersHeader {
  width: 100px;
  height: 140px;
  margin-right: 20px;
  margin-bottom: 4px;
  float: left;
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  -webkit-transform: translateZ(0);

  img {
    width: 100px;
    height: 100%;
  }

}

.picIcon {
  position: absolute;
  right: -6px;
  top: -5px;
  width: 16px;
  height: 16px;
  background: url(../../assets/img/schoolIndex/close.jpg) no-repeat;
  background-size: 100% 100%;
}

.teacherInfo {
  float: left;

  .tackList {
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;

    .name {
      //width: 98px;
      //height: 40px;
      //margin-right: 20px;
    }

    .sex {
      //width: 70px;
      //height: 40px;
      margin-right: 20px;
    }

    .education {
      //width: 70px;
      //height: 40px;
    }
  }

  .intro {
    color: #888;
    font-size: 14px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      width: 98px;

    }
  }
}

.teacherList {
  margin-bottom: 20px;
  overflow: hidden;

  label {
    display: block;
    color: #888;
    margin-bottom: 14px;
    font-size: 16px;
  }
}

.uploadElem {
  width: 100px;
  height: 138px;
  border: 1px dashed #DDDDDD;
  border-radius: 6px;
  text-align: center;

  .el-icon-plus {
    line-height: 138px;
  }
}

.widthPic {
  height: 80px;
  img{
    width: 100px;
    height:80px;
  }
  .el-icon-plus {
    line-height: 80px;
  }
}

.inputWidth {
  display: inline-block;
  width: 368px;
}

/deep/ .el-input__inner {
  display: inline-block;
  width: 368px;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
}

/deep/ .el-input__suffix, .el-input__suffix-inner {
  line-height: 40px;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
  img{
    width: 64px;
    height: 64px;
  }
}
.licensePic {
   width: 64px;
  height: 64px;
  border-radius: 50%;
  float: left;
  img {
    width: 100%;
    height: 100%;
  }

  p {
    padding-top: 12px;

    em {
      display: inline-block;
      width: 58px;
      height: 32px;
      background: url(../../assets/img/schoolIndex/Pic_ZzXk_Ks.png) no-repeat;
      background-size: 100% 100%;
    }

    span {
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #888888;
    }
  }
}
</style>
